import React, { useRef, useEffect } from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Thumbnail from "../components/Thumbnail"
import SEO from "../components/SEO"
import gsap from "gsap"
import FixedLink from "../components/FixedLink"

const SprawdzPage = () => {
  let wrapper = useRef(null)

  useEffect(() => {
    let section = wrapper.children
    gsap.fromTo(
      section,
      { y: "+=100", opacity: 0 },
      {
        y: 0,
        opacity: 1,
        stagger: 0.1,
        lazy: true,
      }
    )
  }, [])
  return (
    <Layout>
      <SEO
        title="Galería de manicura"
        description="Galería de manicura"
      />
      <Hero title="Galería" bolded="de manicura" />
      <div className="container mx-auto flex justify-center mb-16">
        <a className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow text-center -mt-10 z-40" href="https://www.neonail-espana.es/inspiraciones/" target="_blank" rel="noreferrer">Ver todos</a>
      </div>
      <div className="container mx-auto">
        <div
          ref={el => {
            wrapper = el
          }}
          className="grid grid-cols-1 gap-4 md:grid-cols-2"
        >
          {/* <Thumbnail category="walentynki" img="valentine" url="https://www.neonail.pl/inspiracje/?plum_sym=walentynki&author="/> */}
          <Thumbnail category="simple" img="simple" url="https://www.neonail-espana.es/inspiraciones/"/>
          <Thumbnail category="primavera" img="spring" url="https://www.neonail-espana.es/inspiraciones/"/>
          <Thumbnail category="verano" img="summer" url="https://www.neonail-espana.es/inspiraciones/" />
          <Thumbnail category="otoño" img="atumn" url="https://www.neonail-espana.es/inspiraciones/"/>
          <Thumbnail category="invierno" img="winter" url="https://www.neonail-espana.es/inspiraciones/"/>

        </div>
      </div>
      <FixedLink />
    </Layout>
  )
}

export default SprawdzPage
